import logo from './logo.svg';
import construction from './assets/construction.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <img src={construction} />
    </div>
  );
}

export default App;
